import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import Date from './Date';
import DateRange from './DateRange';
import Checkbox from './Checkbox';
import Text from './Text';
import Textarea from './Textarea';
import Button from './Button';
import Range from './Range';
import HTML from './HTML';
import WYSIWYG from './WYSIWYG';
import Number from './Number';
import Tags from './Tags';
import ReactTagAutocomplete from 'react-tag-autocomplete';
import ImageUpload from './ImageUpload';
import {formatRangeValue} from "../../../utilities";
import File from "./File";
import _isEqual from 'lodash/isEqual';
import Password from "./Password";
import Alert from "../Alert";
import _get from 'lodash/get';
import Color from "./Color";
import MultiSelect from "./MultiSelect";

export default class Form extends Component {

    static defaultProps = {
        usePadding: true,
        twoColumn: false,
        values: {},
        style: {},
        updatedFields: [],
        updatingFields: [],
        invalidFields: [],
    };

    static propTypes = {

        /** Function to call when the form validates. Returns {isValid, invalidFields[]} */
        onValidation: PropTypes.func,

        /** An array of field names that failed validation. Will show validation message for each. */
        invalidFields: PropTypes.array,

        /** An array of field names that were recently updated; will add a check icon to these fields. */
        updatedFields: PropTypes.array,

        /** An array of field names that are currently updating; will add a spinner icon to these fields. */
        updatingFields: PropTypes.array,

        /**
         * A spec for the form
         */
        fields: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOf([
                'text',
                'password',
                'textarea',
                'html',
                'wysiwyg',
                'number',
                'select',
                'multiselect',
                'date',
                'daterange',
                'checkbox',
                'button',
                'split-buttons',
                'range',
                'generic',
                'tags',
                'tags-autocomplete',
                'image',
                'file',
                'color'
            ]).isRequired,
            title: PropTypes.node,
            placeholder: PropTypes.string,
            help: PropTypes.node,
            style: PropTypes.object,
            /** NOT <select> options; use `choices` for that. These are options that can be passed to each control */
            options: PropTypes.object,
            /** Used for <select> and multiselect options */
            choices: PropTypes.array,
            /** Used for type=generic */
            content: PropTypes.node,
            classes: PropTypes.array,
            addlClasses: PropTypes.string,
            readOnly: PropTypes.bool,
            validationError: PropTypes.node,
            required: PropTypes.bool,
            /** Used for tags-autocomplete */
            autocomplete: PropTypes.object,
        })).isRequired,

        values: PropTypes.object,

        /**
         * Called when a field changes
         * @param {string} name
         * @param {*} value
         */
        onFieldChange: PropTypes.func,

        usePadding: PropTypes.bool,

        twoColumn: PropTypes.bool,

        style: PropTypes.object,

    };

    state = {
        touchedFields: []
    };

    touchField(name) {
        if (!this.isFieldTouched(name)) {
            this.setState({
                touchedFields: [...this.state.touchedFields, name]
            });
        }
    }

    isFieldTouched(name) {
        return this.state.touchedFields.indexOf(name) > -1;
    }

    isFieldUpdating(name) {
        return this.props.updatingFields.indexOf(name) > -1;
    }

    isFieldUpdated(name) {
        return this.props.updatedFields.indexOf(name) > -1;
    }

    constructor(props) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!_isEqual(prevProps.values, this.props.values)) {
            this.validate();
        }
    }

    validate() {

        if (!this.props.onValidation) {
            return;
        }

        const required = this.props.fields.filter(f => f && f.required && f.required === true);
        let missing = [];

        required.forEach(field => {
            const val = this.getValueFor(field.name);
            if (typeof val === 'undefined' || val === null || val === '') {
                missing.push(field);
            }
        });

        const isValid = missing.length === 0;

        this.props.onValidation({isValid, invalidFields: missing});
    }

    getClassName() {
        let classes = ['tidal-form'];
        if (!this.props.usePadding) {
            classes.push('nopadding');
        }

        if (this.props.twoColumn) {
            classes.push('two-column');
        }

        return classes.join(' ');
    }

    renderGroups() {
        return this.props.fields.filter(field => !!field).map((field, index) => this.renderGroup(field, index));
    }

    renderGroup(spec, index) {
        if (!spec) return;

        let borderClasses = [];

        if ((spec.options || {}).useBorderTop) {
            borderClasses.push('border-top');
        }

        if ((spec.options || {}).useBorderBottom) {
            borderClasses.push('border-bottom');
        }

        if (spec.options || {}.float) {
            if (spec.options.float === 'left') {
                borderClasses.push('pull-left');
            } else if (spec.options.float === 'right') {
                borderClasses.push('pull-right');
            }
        }

        const style = (spec.options || {}).groupStyle || {};
        const key = `form-group-key-${(spec.name || spec.title)}-${index}`;

        return (
            <div className={"v3 form-group clearfix " + borderClasses.join(' ')} style={style} key={key}>
                {this.renderTitleFor(spec)}
                {this.renderControl(spec)}
                {spec.help ? <p className="v3 help-block">{spec.help}</p> : null}
                {this.renderValidationErrorFor(spec)}
            </div>
        );
    }

    renderValidationErrorFor(spec) {

        if (!spec) {
            return null;
        }

        const fieldName = spec.name;

        if ((this.props.invalidFields || []).indexOf(fieldName) === -1) {
            return null;
        }

        if (!this.isFieldTouched(fieldName)) {
            return null;
        }

        let validationError = spec.validationError;

        if (validationError === false) {
            return null;
        }

        if (typeof validationError === 'undefined') {
            validationError = 'This field is required.';
        }

        return (
            <Alert
                classes={['danger']}
                content={validationError}
            />
        );


    }

    /**
     * @param spec
     * @param {Boolean} allowUpdatedIcon Set to false for checkboxes to use native checkbox check.
     * @returns {*}
     */
    getUpdatingIconFor(spec, allowUpdatedIcon = true) {
        let icon = null;
        if (allowUpdatedIcon && spec.name && this.isFieldUpdated(spec.name)) {
            icon = <i className="v3 icon check-circle" style={{marginRight: 5}} />
        }

        if (spec.name && this.isFieldUpdating(spec.name)) {
            icon = <i className="fa fa-spinner fa-spin" style={{marginRight: 5}} />
        }

        return icon;
    }

    renderTitleFor(spec) {

        const icon = this.getUpdatingIconFor(spec);
        let shouldRenderTitle = !!spec.title;
        let titleStyle = {};

        if (spec.type === 'checkbox') {
            // Checkboxes don't need a title label
            shouldRenderTitle = false;
        }

        if ((spec.options || {}).hideTitle) {
            // Allow hiding the title
            shouldRenderTitle = false;
        }

        if (!shouldRenderTitle) {
            return null;
        }

        if ((spec.options || {}).useBoldTitle === false) {
            titleStyle = {fontWeight: 300}
        } else {
            titleStyle = {fontWeight: 'bold'}
        }

        let additional = null;

        // Special additional info for range type
        if (spec.type === 'range') {
            let valueFormatter = (spec.options || {}).valueFormatter;
            let value = this.getValueFor(spec.name);
            additional = formatRangeValue(value, valueFormatter);
        }

        return <label>{icon}<span style={titleStyle}>{spec.title}</span> {additional}</label>


    }

    renderControl(spec) {
        switch (spec.type) {
            case 'html':
                return this.renderHtmlControl(spec);
            case 'wysiwyg':
                return this.renderWysiwygControl(spec);
            case 'text':
                return this.renderTextControl(spec);
            case 'password':
                return this.renderPasswordControl(spec);
            case 'number':
                return this.renderNumberControl(spec);
            case 'textarea':
                return this.renderTextareaControl(spec);
            case 'select':
                return this.renderSelectControl(spec);
            case 'multiselect':
                return this.renderMultiSelectControl(spec);
            case 'date':
                return this.renderDateControl(spec);
            case 'daterange':
                return this.renderDateRangeControl(spec);
            case 'checkbox':
                return this.renderCheckboxControl(spec);
            case 'button':
                return this.renderButtonControl(spec);
            case 'split-buttons':
                return this.renderSplitButtonControl(spec);
            case 'range':
                return this.renderRangeControl(spec);
            case 'generic':
                return this.renderGeneric(spec);
            case 'tags':
                return this.renderTagsControl(spec);
            case 'tags-autocomplete':
                return this.renderTagsAutocompleteControl(spec);
            case 'image':
                return this.renderImageUploadControl(spec);
            case 'file':
                return this.renderFileControl(spec);
            case 'color':
                return this.renderColorControl(spec);
        }
    }

    getValueFor(name) {
        const value = _get((this.props.values || {}), name);

        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    renderRangeControl(spec) {
        return <Range
            onChange={this.handleRangeChange.bind(this, spec.name)}
            value={this.getValueFor(spec.name)}
            formatLabel={(spec.options || {}).formatLabel}
            min={(spec.options || {}).min || 0}
            max={(spec.options || {}).max || 100}
            step={(spec.options || {}).step || 1}
        />

    }

    renderHtmlControl(spec) {
        return <HTML
            key={spec.name}
            name={spec.name}
            onChange={this.handleHtmlChange.bind(this, spec.name)}
            style={(spec.style || {})}
            placeholder={spec.placeholder}
            value={this.getValueFor(spec.name)}
        />

    }

    renderWysiwygControl(spec) {
        return <WYSIWYG
            key={spec.name}
            name={spec.name}
            onChange={this.handleWysiwygChange.bind(this, spec.name)}
            style={(spec.style || {})}
            placeholder={spec.placeholder}
            value={this.getValueFor(spec.name)}
        />

    }

    renderNumberControl(spec) {
        return <Number
            key={spec.name}
            placeholder={spec.placeholder}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleFieldChange}
            style={spec.style || {}}
            classes={spec.classes || []}
            readOnly={spec.readOnly || false}
            min={(spec.options || {}).min}
            max={(spec.options || {}).max}
            step={(spec.options || {}).step}
        />
    }

    renderPasswordControl(spec) {
        return <Password
            key={spec.name}
            placeholder={spec.placeholder}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleFieldChange}
            style={spec.style || {}}
            classes={spec.classes || []}
            readOnly={spec.readOnly || false}
        />
    }

    renderTextControl(spec) {
        return <Text
            key={spec.name}
            placeholder={spec.placeholder}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleFieldChange}
            style={spec.style || {}}
            classes={spec.classes || []}
            readOnly={spec.readOnly || false}
        />
    }

    renderTextareaControl(spec) {
        return <Textarea
            placeholder={spec.placeholder}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleFieldChange}
            style={spec.style || {}}
            readOnly={spec.readOnly || false}
        />
    }

    renderMultiSelectControl(spec) {
        return <MultiSelect
            choices={spec.choices}
            addlClasses={spec.addlClasses || ''}
            onChange={this.handleSelectChange.bind(this, spec.name)}
            value={this.getValueFor(spec.name) || []}
        />
    }

    renderSelectControl(spec) {
        return <Select
            options={spec.choices}
            defaultValue={(spec.options || {}).defaultValue}
            searchable={(spec.options || {}).searchable}
            onChange={this.handleSelectChange.bind(this, spec.name)}
            value={this.getValueFor(spec.name)}
            addlClasses={spec.addlClasses || ''}
        />
    }

    renderDateControl(spec) {
        return <Date
            placeholder={spec.placeholder}
            onChange={this.handleDateChange.bind(this, spec.name)}
            value={this.getValueFor(spec.name)}
            options={spec.options}
        />
    }

    renderDateRangeControl(spec) {
        return <DateRange
            placeholder={spec.placeholder}
            onChange={this.handleDateRangeChange.bind(this, spec.name)}
            value={this.getValueFor(spec.name)}
        />
    }

    renderCheckboxControl(spec) {
        const icon = this.getUpdatingIconFor(spec, false);
        const readOnly = !!spec.readOnly;
        return <Checkbox
            checked={this.getValueFor(spec.name)}
            label={spec.title}
            onClick={readOnly ? undefined : this.handleCheckboxChange.bind(this, spec.name)}
            icon={icon}
            useBoldTitle={(spec.options || {}).useBoldTitle}
            wrapperStyle={spec.wrapperStyle}
        />

    }

    renderButtonControl(spec) {

        let classes = ((spec.options || {}).classes || []);
        const before = (spec.options || {}).before || null;
        const after = (spec.options || {}).after || null;

        return (
            <>
                {before}
                <Button
                    onClick={(spec.options || {}).onClick}
                    classes={classes}
                    style={spec.style || {}}
                    content={spec.title}
                />
                {after}
            </>
        );

    }

    renderSplitButtonControl(spec) {
        return <div className="clearfix">
            {spec.left ? this.renderButtonControl({...spec.left, style: {...spec.left.style, float: 'left'}}) : null}
            {spec.right ? this.renderButtonControl({...spec.right, style: {...spec.right.style, float: 'right'}}) : null}
        </div>
    }

    renderColorControl(spec) {
        return <Color
            options={spec.options || {}}
            value={this.getValueFor(spec.name)}
            onChange={(color, event) => {
                this.touchField(spec.name);
                this.props.onFieldChange(spec.name, color);
            }}
        />
    }

    renderGeneric(spec) {
        if (typeof spec.render === 'function') {
            return spec.render(this.props.values, this.props.onFieldChange);
        }
        return spec.content;
    }

    renderTagsAutocompleteControl(spec) {

        const defaults = {
            allowNew: false,
            addOnBlur: false,
            autofocus: false,
            suggestions: [],
            handleInputChange: () => {}
        };

        const opts = {...defaults, ...(spec.autocomplete || {})};
        const values = this.getValueFor(spec.name) || [];

        return <ReactTagAutocomplete
            key={spec.name}
            placeholder={spec.placeholder}
            allowNew={opts.allowNew}
            addOnBlur={opts.addOnBlur}
            autofocus={opts.autofocus}
            suggestions={opts.suggestions}
            tags={values}
            handleDelete={index => {
                if (this.props.onFieldChange) {
                    let newVals = {...values};
                    delete newVals[index];
                    newVals = Object.values(newVals);
                    this.props.onFieldChange(spec.name, newVals);
                }
            }}
            handleAddition={item => {
                if (this.props.onFieldChange) {
                    this.props.onFieldChange(spec.name, [...values, item]);
                }
            }}
            handleInputChange={opts.handleInputChange}
        />;
    }

    renderTagsControl(spec) {
        return <Tags
            key={spec.name}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleTagsChange.bind(this, spec.name)}
            style={spec.style || {}}
        />
    }

    renderFileControl(spec) {
        return <File
            key={spec.name}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleFileChange.bind(this, spec)}
            onForceChange={this.handleForceFileChange.bind(this, spec.name)}
            previewFormatter={(spec.options || {}).previewFormatter}
            style={spec.style || {}}
            accept={(spec.options || {}).accept || undefined}
        />

    }

    renderImageUploadControl(spec) {
        return <ImageUpload
            key={spec.name}
            name={spec.name}
            value={this.getValueFor(spec.name)}
            onChange={this.handleImageChange.bind(this, spec.name)}
            style={spec.style || {}}
        />
    }

    handleFieldChange(event) {
        const {name, value} = event.target;
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, value);
        }
    }

    handleCheckboxChange(name, event) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, !this.getValueFor(name));
        }
    }

    handleSelectChange(name, option) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, option.value);
        }
    }

    handleDateChange(name, date) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, date);
        }
    }

    handleDateRangeChange(name, daterange) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, daterange);
        }
    }

    handleRangeChange(name, value) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, value);
        }
    }

    handleHtmlChange(name, value) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, value);
        }
    }

    handleWysiwygChange(name, value) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, value);
        }
    }

    handleTagsChange(name, tags) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, tags);
        }
    }

    handleForceFileChange(name, files) {
        this.touchField(name);
        if (this.props.onFieldChange) {
            this.props.onFieldChange(name, files);
        }
    }

    handleFileChange(spec, event) {

        if (event === null && this.props.onFieldChange) {
            return this.props.onFieldChange(spec.name, null);
        }

        const onChange = (spec.options || {}).onChange;
        if (!onChange) {
            return;
        }

        const promise = onChange(event);

        if (promise) {
            promise.then(val => {
                if (this.props.onFieldChange) {
                    this.props.onFieldChange(spec.name, val);
                }
            });
        }

    }

    handleImageChange(name, imageID, imageObj, removeId) {
        this.touchField(name);
        if(this.props.onFieldChange) {
            this.props.onFieldChange(name, imageID, imageObj, removeId);
        }
    }

    render() {

        return (
            <div className={this.getClassName()} style={this.props.style}>
                {this.renderGroups()}
            </div>
        );

    }
}
