import {formatNumber, formatNumberK} from "../../../utilities";
import {socialIconForType, socialNameForType, socialTypeFromNiceName} from "../../../utilities/social";
import React from "react";
import _uniq from 'lodash/uniq';

function preprocessReachItem(spec, socials) {
    let value, formattedValue, labelText, icon;
    const [specSocial, specType] = spec.split('.');
    let filteredSocials = (socials || []).filter(s => {
        if (specSocial === 'total') {
            return true;
        }
        return s.type === socialTypeFromNiceName(specSocial);
    });
    const sum = (field) => filteredSocials.reduce((total, item) => total += parseFloat(item[field] || 0), 0);
    const countWithField = (field) => filteredSocials.filter(i => !!i[field]).length;

    if (!icon) {
        icon = socialIconForType(specSocial);
    }

    switch (specType) {

        case 'reach':
        case 'followers_count':
            value = sum('followers_count');
            formattedValue = formatNumberK(value);
            labelText = 'Reach';
            break;

        case 'posts':
            value = sum('posts_count');
            formattedValue = formatNumber(value);
            labelText = 'Posts';
            break;

        case 'avg_engagement':
            value = sum('avg_engagement');
            formattedValue = formatNumberK(value);
            labelText = 'Engs. per Post';
            break;

        case 'engagement_pct':

            value = sum('avg_engagement_pct') / countWithField('avg_engagement_pct');
            formattedValue = formatNumberK(value * 100, 1) + '%';
            labelText = 'Engagement Rate';
            break;

    }
    const socialToLink = filteredSocials[0];
    const socialUrl = socialToLink ? socialToLink.url : '#';
    const label = (<a href={socialUrl} className="dark" rel="noopener noreferrer" target="_blank">{labelText}</a>) ;
    return {value, formattedValue, label, icon};
}

function ReachItem({socials, spec}) {
    let {value, formattedValue, label, icon} = preprocessReachItem(spec, socials);

    if (typeof value === 'undefined' || isNaN(value) || value === 0) {
        console.log("Value is undefined or null, quitting");
        return null;
    }

    return (
        <div className="small-block">
            <figure>{formattedValue}</figure>
            <figcaption><i className={icon} /> {label}</figcaption>
        </div>

    );
}
function PortfolioReachWidget({item}) {
    const socials = item.profile.socials || [];

    let defaultSpecs = [
        'instagram.reach',
        'instagram.avg_engagement',
        'instagram.engagement_pct',
        'tiktok.reach',
        'tiktok.avg_engagement',
        'tiktok.engagement_pct',
    ];

    if (item.settings && item.settings.enabled_stats) {
        defaultSpecs = item.settings.enabled_stats;
    }

    const ReachItems = _uniq(defaultSpecs).map(spec => {
        const preprocessed = preprocessReachItem(spec, socials);
        if (typeof preprocessed.value === 'undefined' || isNaN(preprocessed.value) || preprocessed.value === 0) {
            return null;
        }
        return ( <ReachItem socials={socials} spec={spec} key={spec} /> );
    })
        .filter(o => o !== null)
        .slice(0, 12);

    return (
        <div className="reach-box">
            <h5 className="v3">Social Reach</h5>
            <div className="reach-contents">

                <div className="large-block">
                    <figure>{formatNumberK(item.profile.reach)}</figure>
                    <figcaption>Total Reach</figcaption>
                </div>

                <div className="small-blocks">
                    {ReachItems}
                </div>

            </div>

        </div>

    );
}

export default PortfolioReachWidget;