import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from '../../Common/Form/Form';
import NavStacked from '../../Common/NavStacked';
import Alert from '../../Common/Alert';
import CampaignCouponsTable from '../Coupons/Table';
import ConnectedCampaignProductsTable from '../../../containers/Products/CampaignProductsTable';
import EmailSettingsTable from './EmailSettingsTable';
import SectionalToolbox from '../../Common/SectionalToolbox';
import AddCatalog from '../../Products/AddCatalog';
import {connect} from 'react-redux';
import {getTeams} from "../../../selectors/teams";
import {getCatalogs} from "../../../selectors/products";
import {fetchProductCatalogs} from "../../../actions/products";
import {fetchCampaignForms, fetchLists} from "../../../actions";
import {getCampaignForms, getCampaignsByDate} from "../../../selectors/campaigns";
import {fetchAuthenticated} from "../../../utilities";
import {getWorkflows} from "../../../selectors/workflows";
import {fetchWorkflowsIfNeeded} from "../../../actions";
import {getCurrentChannel} from "../../../selectors/presence";
import {createChannelInteraction} from "../../../actions/channel";
import {DEFAULT_REPORT_SPEC} from "../../Report/helpers";
import _set from 'lodash/set';
import _merge from 'lodash/merge';
import {getLists} from "../../../selectors/lists";

class CampaignSettingsForm extends Component {

    static defaultProps = {
        style: {},
        activationFieldsOnly: false,
        isMobile: false,
    };

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        campaigns: PropTypes.arrayOf(PropTypes.object),
        workflows: PropTypes.arrayOf(PropTypes.object),
        teams: PropTypes.array,
        lists: PropTypes.array,
        onSave: PropTypes.func.isRequired,
        style: PropTypes.object,
        catalogs: PropTypes.array,
        forms: PropTypes.array,
        fetchProductCatalogs: PropTypes.func,
        fetchForms: PropTypes.func,
        fetchLists: PropTypes.func,
        fetchWorkflows: PropTypes.func,
        activationFieldsOnly: PropTypes.bool,
        settings: PropTypes.object.isRequired,
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            view: props.activationFieldsOnly ? 'content' : 'basics',
            showAddCatalog: false,
            updatingFields: {},
            updatedFields: {},
            errorFields: {},
            pendingUpdates: {},
            isUpdating: false,
            didUpdate: false,
            isFetchingTemplates: false,
            templates: [],
        };
        this.toggleAddCatalog = this.toggleAddCatalog.bind(this);
    }

    componentDidMount() {
        this.props.fetchProductCatalogs();
        this.props.fetchLists();
        this.props.fetchForms(this.props.campaign.id);

        if (!this.props.activationFieldsOnly) {
            this.props.fetchWorkflows();
            this.fetchTemplates();
        }
    }

    fetchTemplates() {
        const defaultTemplate = {
            id: 'default',
            name: 'Default Template',
            template: [...DEFAULT_REPORT_SPEC],
            settings: {
                readOnly: true,
            }
        };

        this.setState({isFetchingTemplates: true});
        return this.props.fetchReportTemplates()
            .then(json => {
                this.setState({
                    isFetchingTemplates: false,
                    templates: [defaultTemplate, ...Object.values(json.data.results)]
                });
            })
    }

    handleUploadAttachment(event) {

        const slot = window.Gator.getDashboardSlot();
        const data = new FormData();
        data.append('attachment', event.target.files[0]);

        const url = `/${slot}/api/email/-1/attachment`;

        return fetchAuthenticated(url, {
            method: 'post',
            body: data,
        })
            .then(resp => resp.json())
            .then(json => json.data);

    }

    handleChangeSection(view) {
        this.setState({view});
    }

    isSectionActive(view) {
        return this.state.view === view;
    }

    renderNav() {
        const settings = this.getSettingsObject();
        const isReportPublic = !!settings.share_report;
        const showCampaignFields = !this.props.activationFieldsOnly;

        return (
            <div className="col-xs-12 col-md-4">
                <NavStacked
                    isHorizontal={this.props.isMobile}
                    items={[
                        showCampaignFields ? {
                            title: "Basic Settings",
                            onClick: this.handleChangeSection.bind(this, 'basics'),
                            isActive: this.isSectionActive('basics'),
                        } : null,
                        {
                            title: "Content Requirements",
                            onClick: this.handleChangeSection.bind(this, 'content'),
                            isActive: this.isSectionActive('content'),
                        },
                        {
                            title: "Logistics",
                            onClick: this.handleChangeSection.bind(this, 'logistics'),
                            isActive: this.isSectionActive('logistics'),
                        },
                        {
                            title: "Copy & Communication",
                            onClick: this.handleChangeSection.bind(this, 'templates'),
                            isActive: this.isSectionActive('templates'),
                        },
                        {
                            title: "Advanced Settings",
                            onClick: this.handleChangeSection.bind(this, 'advanced'),
                            isActive: this.isSectionActive('advanced'),
                        },
                        (showCampaignFields && settings.use_products) ? {
                            title: "Products Management",
                            onClick: this.handleChangeSection.bind(this, 'products'),
                            isActive: this.isSectionActive('products'),
                        }: null,
                        (showCampaignFields && settings.use_coupon_codes) ? {
                            title: "Coupon Management",
                            onClick: this.handleChangeSection.bind(this, 'coupons'),
                            isActive: this.isSectionActive('coupons'),
                        }: null,
                        (isReportPublic && showCampaignFields) ? {
                            title: "Report Sharing",
                            onClick: this.handleChangeSection.bind(this, 'report'),
                            isActive: this.isSectionActive('report')
                        } : null

                    ]}
                />
            </div>
        );
    }

    getSettingsObject() {
        const campaign = this.props.campaign || {};
        const settings = {...this.props.settings || {}};
        const native = {
            name: campaign.name,
            slug: campaign.slug,
            description: campaign.description,
            start: campaign.start,
            end: campaign.end,
            image_id: campaign.image_id,
            billable_team_id: campaign.billable_team_id,
            tags: campaign.tags,
            archived: !!campaign.archived,
            parent_id: campaign.parent_id
        };

        const pending = {...this.state.pendingUpdates};

        const merged = _merge({}, settings, native, pending);

        if (merged.hashtag && typeof merged.hashtag === 'string') {
            merged.hashtag = merged.hashtag.split(',').map(tag => tag.trim());
            if (!merged.hashtag.length) {
                merged.hashtag = '';
            }
        }

        return merged;
        // return {...settings, ...native, ...pending};
    }

    markFieldUpdating(name, isUpdating = true) {
        this.setState({
            updatingFields: {
                ...this.state.updatingFields,
                [name]: isUpdating
            }
        });
    }

    markFieldUpdated(name, didUpdate = true) {
        this.setState({
            updatedFields: {
                ...this.state.updatedFields,
                [name]: didUpdate
            }
        });
    }

    markFieldError(name, error) {
        this.setState({
            errorFields: {
                ...this.state.errorFields,
                [name]: error
            }
        });

    }

    toggleAddCatalog() {
        this.setState({
            showAddCatalog: !this.state.showAddCatalog
        })
    }

    commitUpdates() {

        const pending = {...this.state.pendingUpdates};

        this.setState({isUpdating: true});
        let promise = this.props.onSave(pending);

        promise.then(() => {
            const nowPending = {...this.state.pendingUpdates};
            Object.keys(pending)
                .forEach(name => {
                    this.markFieldUpdating(name, false);
                    this.markFieldUpdated(name, true);
                    delete(nowPending[name]);
                });

            this.setState({pendingUpdates: nowPending, isUpdating: false, didUpdate: true});

            setTimeout(() => {
                Object.keys(pending)
                    .forEach(name => this.markFieldUpdated(name, false));
                this.setState({didUpdate: false});
            }, 3000);
        });

    }

    handleFieldChange(name, value) {

        this.markFieldUpdating(name, true);

        if (name.indexOf('share_report_vars.') === 0 && value === '') {
            value = null;
        }

        if (name === 'hashtag' && typeof value !== 'string') {
            value = value.join(', ');
        }

        let newPendingUpdates = _set(this.state.pendingUpdates, name, value);

        this.setState({
            pendingUpdates: newPendingUpdates
        });

    }

    isFieldUpdating(name) {
        return !!this.state.updatingFields[name];
    }

    isFieldUpdated(name) {
        return !!this.state.updatedFields[name];
    }

    getUpdatingFields() {
        return Object.keys(this.state.updatingFields)
            .filter(name => this.isFieldUpdating(name));
    }

    getUpdatedFields() {
        return Object.keys(this.state.updatedFields)
            .filter(name => this.isFieldUpdated(name));
    }

    renderLogisticsPane() {
        const settings = this.getSettingsObject();
        const {showAddCatalog} = this.state;
        const createCatalogLink = <a role='button' className='bold' onClick={this.toggleAddCatalog}>create a catalog</a>;
        const isPaymentsEnabled = !this.props.channel.disable_payments;
        const isAffiliateEnabled = !!settings.use_affiliate_links;

        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={this.getSettingsObject()}
            fields={[
                {
                    type: 'checkbox',
                    name: 'invite_only',
                    title: 'Invite-Only',
                    help: 'Only shows the campaign in the hub for users who have been added to the campaign.',
                },
                // {
                //     type: 'checkbox',
                //     name: 'force_complete_profile',
                //     title: "Add 'Complete Profile' Step",
                //     help: "If enabled, adds a step that requires users to complete missing profile fields."
                // },
                {
                    type: 'checkbox',
                    name: 'auto_approve_content',
                    title: 'Automatically Approve Content',
                    help: 'If enabled, submitted posts will not require manual approval by a manager.',
                    options: {useBorderTop: true},
                },
                isPaymentsEnabled ? {
                    type: 'checkbox',
                    name: 'use_payments',
                    title: 'Use Cash Payments',
                    help: 'Will require users to submit payment profiles.',
                    options: {useBorderTop: true},
                } : null,
                (isPaymentsEnabled && settings.use_payments === true) ? {
                    type: 'number',
                    name: 'budget',
                    title: 'Total Budget',
                    help: 'Total budget for cash payments.',
                    options: {min: 0, max: 1000000, step: 1, useBoldTitle: false},
                } : null,
                (isPaymentsEnabled && settings.use_payments === true) ? {
                    type: 'checkbox',
                    name: 'collect_payment_details',
                    title: 'Collect Payment Details',
                    help: 'Disable only if you have a custom payment workflow.',
                } : null,
                isPaymentsEnabled ? {
                    type: 'checkbox',
                    name: 'use_products',
                    title: 'Send Product Samples',
                    help: 'Will require users to submit shipping details.',
                    options: {useBorderTop: true},
                } : null,
                (isPaymentsEnabled && settings.use_products === true) ? {
                    type: 'select',
                    name: 'product_catalog_id',
                    title: 'Product Catalog',
                    help: <p>Select a Product Catalog to attach to this campaign, or {createCatalogLink}.</p>,
                    choices: [
                        {text: "Don't Allow Product Selection", value: null},
                        ...(this.props.catalogs || []).map(cat => ({text: cat.name, value: cat.id}))
                    ],
                    options: {useBoldTitle: false},
                } : null,
                showAddCatalog ? {
                    type: 'generic',
                    options: {
                        groupStyle: {
                            margin: 0
                        }
                    },
                    content: <AddCatalog 
                        onClose={this.toggleAddCatalog} 
                        refresh={this.props.fetchProductCatalogs}
                        embedded={true}
                    />
                } : null,
                (settings.use_products === true && settings.product_catalog_id) ? {
                    type: 'number',
                    name: 'number_samples',
                    title: 'Product Samples Per User',
                    help: 'The number of products to send to each user.',
                    options: {min: 0, max: 10, step: 1, useBoldTitle: false},
                } : null,
                isPaymentsEnabled ? {
                    type: 'checkbox',
                    name: 'use_coupon_codes',
                    title: 'Gift Coupon Codes to Users',
                    help: 'Send coupon codes in addition to or instead of cash payments.',
                    options: {useBorderTop: true},
                } : null,
                {
                    type: 'checkbox',
                    name: 'use_affiliate_links',
                    title: 'Use Affiliate Links',
                    help: 'Allow users to generate their own affiliate links for campaign products.',
                    options: {useBorderTop: true},
                },
                (isAffiliateEnabled) ? {
                    type: 'select',
                    name: 'affiliate_product_catalog_id',
                    title: 'Product Catalog for Affiliate Links',
                    help: <p>Select a Product Catalog to attach to this campaign, or {createCatalogLink}.</p>,
                    choices: [
                        {text: "Don't Allow Product Selection", value: null},
                        ...(this.props.catalogs || []).map(cat => ({text: cat.name, value: cat.id}))
                    ],
                    options: {useBoldTitle: false},
                } : null,
                {
                    type: 'checkbox',
                    name: 'waitlist_by_default',
                    title: 'Waitlist By Default',
                    help: 'Automatically waitlists users that accept campaign invitations.',
                    options: {useBorderTop: true},
                },
                {
                    type: 'checkbox',
                    name: 'use_deadlines',
                    title: 'Use Deadlines',
                    help: 'If enabled, will enforce deadlines for post and draft submissions.',
                    options: {useBorderTop: true},
                },
                settings.use_deadlines ? {
                    type: 'date',
                    name: 'invitation_deadline_at',
                    title: 'Invitation Deadline',
                    help: 'Creators must accept the invitation before this date.',
                    options: {
                        useBoldTitle: false,
                        showTimeSelect: true,
                        dateFormat: 'MMM Do, YYYY [at] h:mma (ZZ)'
                    },
                } : null,
                settings.use_deadlines ? {
                    type: 'date',
                    name: 'post_deadline_at',
                    title: 'Post Submission Deadline',
                    help: 'The deadline for post submissions.',
                    options: {
                        useBoldTitle: false,
                        showTimeSelect: true,
                        dateFormat: 'MMM Do, YYYY [at] h:mma (ZZ)'
                    },
                } : null,
                (settings.use_deadlines && (settings.use_blog_drafts || settings.use_social_drafts)) ? {
                    type: 'date',
                    name: 'draft_deadline_at',
                    title: 'Draft Submission Deadline',
                    help: 'The deadline for draft submissions.',
                    options: {
                        useBoldTitle: false,
                        showTimeSelect: true,
                        dateFormat: 'MMM Do, YYYY [at] h:mma (ZZ)'
                    },
                } : null,
                (settings.use_deadlines && settings.use_products) ? {
                    type: 'date',
                    name: 'shipping_deadline_at',
                    title: 'Shipping Address Deadline',
                    help: 'The deadline for users to submit their shipping address. Reminder emails, if enabled, are sent 24 hours earlier.',
                    options: {
                        useBoldTitle: false,
                        showTimeSelect: true,
                        dateFormat: 'MMM Do, YYYY [at] h:mma (ZZ)'
                    },
                } : null,
                settings.use_deadlines ? {
                    type: 'select',
                    name: 'cancel_after_deadline',
                    title: 'When Deadline is Missed...',
                    help: 'Choose what to do when a deadline (post or draft submission, or invitation) is missed.',
                    choices: [
                        {text: 'Send Reminders Only', value: false},
                        {text: 'Remind, then Cancel', value: true},
                    ],
                    options: {useBoldTitle: false},
                } : null,
                {
                    type: 'checkbox',
                    name: 'use_nda',
                    title: 'Require NDA',
                    help: 'If enabled, will require users to sign a Non-Disclosure Agreement.',
                    options: {useBorderTop: true},
                },
                settings.use_nda === true ? {
                    type: 'html',
                    name: 'nda_about',
                    title: 'NDA Information',
                    help: 'Information about the NDA.',
                } : null,
                settings.use_nda === true ? {
                    type: 'html',
                    name: 'nda_contract',
                    title: 'NDA Contract',
                    help: 'The NDA contract itself.',
                } : null,
                {
                    type: 'checkbox',
                    name: 'use_custom_form',
                    title: 'Collect Additional Data from Users',
                    help: 'If enabled, will require users to fill out a custom form as part of their activation.',
                    options: {useBorderTop: true},
                },
                settings.use_custom_form === true ? {
                    type: 'select',
                    name: 'custom_form_id',
                    title: 'Custom Form',
                    help: 'Select a custom form to require users to complete.',
                    choices: [
                        {text: '- Select a Form -', value: null},
                        ...(this.props.forms || []).map(form => ({text: form.name, value: form.id}))
                    ],
                    options: {useBoldTitle: false},

                } : null,

            ]}
        />
    }

    renderContentPane() {
        const settings = this.getSettingsObject();
        const areDraftsAllowedByPlan = ['advanced', 'enterprise'].indexOf(this.props.channel.plan_type) > -1;
        const DraftsPlanNotice = areDraftsAllowedByPlan ? '' : <strong>Only available in Advanced and Enterprise plans.</strong>

        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={this.getSettingsObject()}
            fields={[
                {
                    type: 'checkbox',
                    name: 'use_blog_drafts',
                    title: 'Require Drafts for Long-Form Posts',
                    help: <div>Will require users to submit drafts for blog posts and other long-form content. {DraftsPlanNotice}</div>,
                    readOnly: !areDraftsAllowedByPlan,
                },
                {
                    type: 'checkbox',
                    name: 'use_social_drafts',
                    title: 'Require Drafts for Social Posts',
                    help: <div>Will require users to submit drafts for social posts. {DraftsPlanNotice}</div>,
                    readOnly: !areDraftsAllowedByPlan,
                },
                (settings.use_deadlines && (settings.use_blog_drafts || settings.use_social_drafts)) ? {
                    type: 'date',
                    name: 'draft_deadline_at',
                    title: 'Draft Submission Deadline',
                    help: 'The deadline for draft submissions.',
                    options: {useBoldTitle: false}
                } : null,
                {
                    type: 'number',
                    name: 'num_blog_posts',
                    title: 'Blog Posts Required',
                    options: {min: 0, max: 5, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_instagram_posts',
                    title: 'Instagram Posts Required',
                    options: {min: 0, max: 10, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_instastories',
                    title: 'Instastories Required',
                    options: {min: 0, max: 25, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_twitter_posts',
                    title: 'Twitter Posts Required',
                    options: {min: 0, max: 10, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_pinterest_posts',
                    title: 'Pinterest Posts Required',
                    options: {min: 0, max: 10, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_facebook_page_posts',
                    title: 'Facebook Page Posts Required',
                    options: {min: 0, max: 10, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_youtube_posts',
                    title: 'YouTube Videos Required',
                    options: {min: 0, max: 10, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_tiktok_posts',
                    title: 'TikTok Videos Required',
                    options: {min: 0, max: 10, step: 1},
                },
                {
                    type: 'number',
                    name: 'num_other_posts',
                    title: 'Other Social Posts Required',
                    options: {min: 0, max: 10, step: 1},
                    help: "This allows the user to submit content to their social network of choice."
                },
                {
                    type: 'number',
                    name: 'num_images',
                    title: 'High-Resolution Images Required',
                    options: {min: 0, max: 50, step: 1},
                    help: 'This setting requires users to separately upload high-resolution images. Images included in blog or social posts are not counted here.'
                },
                {
                    type: 'number',
                    name: 'num_videos',
                    title: 'Video Uploads Required',
                    options: {min: 0, max: 5, step: 1},
                    help: 'This setting requires users to separately upload raw video files. Videos from YouTube submissions are not counted here.'
                },
            ]}
        />
    }

    renderAdvancedPane() {

        const settings = this.getSettingsObject();
        const showCampaignFields = !this.props.activationFieldsOnly;
        const isReportPublic = !!settings.share_report;
        const isManagementPublic = !!settings.share_management;
        const shareReportUrl = settings.share_report_url;
        const shareDraftsUrl = settings.share_drafts_url;

        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={settings}
            fields={[
                showCampaignFields ? {
                    type: 'text',
                    name: 'slug',
                    title: 'Campaign Slug',
                    help: 'An URL-friendly version of the campaign name.',
                    placeholder: 'eg, summer-style-campaign',
                } : null,
                showCampaignFields ? {
                    type: 'tags',
                    name: 'tags',
                    title: 'Campaign Tags',
                    help: 'Used for custom integrations. Press TAB or RETURN/ENTER after each tag.',
                    options: {useBorderTop: true},
                } : null,
                showCampaignFields ? {
                    type: 'select',
                    title: 'Make this a Subcampaign Of',
                    name: 'parent_id',
                    choices: [
                        {value: null, text: '- Standalone Campaign -'},
                        ...this.props.campaigns
                            .filter(c => c.id !== (this.props.campaign || {}).id)
                            .map(c => ({value: c.id, text: c.name}))
                    ],
                    options: {useBorderTop: true},
                } : null,
                showCampaignFields ? {
                    type: 'checkbox',
                    name: 'monitor_hashtag',
                    title: "Monitor Hashtags",
                    help: "Enable this option to automatically submit hashtagged content on behalf of activated users.",
                    options: {useBorderTop: true},
                } : null,
                (showCampaignFields) ? {
                    type: 'tags',
                    name: 'hashtag',
                    title: "Hashtag(s) to Monitor",
                    help: <span>Press TAB or RETURN/ENTER after each hashtag. We grab posts that use <em>any</em> of these tags.</span>,
                    options: {useBorderTop: false},
                } : null,
                (showCampaignFields) ? {
                    type: 'select',
                    name: 'monitor_hashtag_source',
                    title: "Hashtag Monitoring Source",
                    help: "Specify which accounts are considered for the hashtag monitor.",
                    choices: [
                        {value: null, text: 'All Users'},
                        {value: 'campaign', text: 'Activated Users in This Campaign'},
                        ...(this.props.teams || []).map(team => ({value: `team:${team.id}`, text: `Team: ${team.name}`})),
                        ...(this.props.lists || []).map(list => ({value: `list:${list.id}`, text: `List: ${list.name}`}))
                    ],
                    options: {useBorderTop: false},
                } : null,
                {
                    type: 'checkbox',
                    name: 'use_custom_contract',
                    title: "Use Custom Contract",
                    help: "Enable this option to use a custom Influencer Services Agreement for this campaign.",
                    options: {useBorderTop: true},
                },
                settings.use_custom_contract === true ? {
                    type: 'text',
                    title: 'Custom Contract URL',
                    name: 'custom_contract_url',
                    help: "The URL for the custom contract. Should point either to a webpage with the terms displayed, or a downloadable PDF document.",
                    options: {useBoldTitle: false},
                } : null,
                settings.use_custom_contract === true ? {
                    type: 'checkbox',
                    title: 'Disable Contract',
                    name: 'disable_contract',
                    help: "Disables the contract/terms entirely. Only to be used if users have previously agreed to terms.",
                    options: {useBoldTitle: false},
                } : null,
                {
                    type: 'checkbox',
                    name: 'auto_accept_invitation',
                    title: "Unattended Campaign Mode",
                    help: "Enable if users are not expected to interact with the campaign. This will automatically 'accept' the invitation on their behalf in order to enable reporting.",
                    options: {useBorderTop: true},
                },
                {
                    type: 'checkbox',
                    name: 'should_reactivate',
                    title: "Add to Other Campaign When Complete",
                    help: "Automatically activate the user in another campaign when they've completed this one.",
                    options: {useBorderTop: true},
                },
                settings.should_reactivate === true ? {
                    type: 'select',
                    title: 'Select Campaign to Add To When Complete',
                    name: 'reactivate_campaign_id',
                    help: "Choose the campaign that users should be added to when they complete this one.",
                    choices: [
                        {value: null, text: '- Select a Campaign -'},
                        ...this.props.campaigns.map(campaign => ({text: campaign.name, value: campaign.id}))
                    ],
                    options: {useBoldTitle: false},
                } : null,
                showCampaignFields ? {
                    type: 'select',
                    title: 'Convert to Workflow Campaign',
                    name: 'workflow_id',
                    help: 'If you need an older Workflow-style campaign, you can select your workflow here.',
                    choices: [
                        {value: null, text: "Don't Use Workflows"},
                        ...this.props.workflows.map(wf => ({text: wf.name, value: wf.id}))
                    ],
                    options: {useBorderTop: true},
                } : null,


                showCampaignFields ? {
                    type: 'checkbox',
                    name: 'share_report',
                    title: 'Publicly Share Campaign Report',
                    options: {useBorderTop: true, hideTitle: true}
                } : null,
                (isReportPublic && shareReportUrl && showCampaignFields) ? {
                    type: 'generic',
                    content: (<Alert classes={['info']} content={<div>Customize your report in the <a rel="button" onClick={() => this.handleChangeSection('report')} style={{fontWeight: 'bold', cursor: 'pointer', color: 'white'}}>Report Sharing</a> panel.</div>} />)
                } : null,


                showCampaignFields ? {
                    type: 'checkbox',
                    name: 'share_management',
                    title: 'Publicly Share Campaign Drafts',
                    options: {useBorderTop: true, hideTitle: true}
                } : null,

                (showCampaignFields && shareDraftsUrl && isManagementPublic) ? {
                    type: 'generic',
                    content: (<Alert classes={['info']} content={<div>Your public drafts feedback URL is: {shareDraftsUrl}</div>} />)
                } : null,

                (showCampaignFields && isManagementPublic) ? {
                    type: 'text',
                    name: 'share_management_password',
                    title: 'Public Drafts Password (Optional)',
                } : null,

                showCampaignFields ? {
                    type: 'checkbox',
                    title: 'Archive Campaign',
                    name: 'archived',
                    options: {hideTitle: true, useBorderTop: true},
                } : null,

            ]}
        />
    }

    renderReportPane() {
        if (this.props.activationFieldsOnly) {
            return <Alert classes={['info']} content={"These settings only apply to the campaign, not to individual activations."} />
        }

        const settings = this.getSettingsObject();
        const showCampaignFields = !this.props.activationFieldsOnly;
        const isReportPublic = !!settings.share_report;
        const shareReportUrl = settings.share_report_url;

        const publicReportFields = isReportPublic ? [
            {
                type: 'select',
                name: 'share_report_template',
                title: 'Template for Public Report',
                choices: (this.state.templates || []).map(t => ({text: t.name, value: t.id}))
            },
            {
                type: 'text',
                name: 'share_report_password',
                title: 'Public Campaign Report Password (Optional)',
            },
            {
                type: 'textarea',
                name: 'share_report_objective',
                title: 'Campaign Objective Summary'
            },
            {
                type: 'textarea',
                name: 'share_report_strategy',
                title: 'Campaign Strategy Summary'
            },
            {
                type: 'textarea',
                name: 'share_report_results',
                title: 'Campaign Results Summary'
            },
            {
                type: 'image',
                name: 'share_report_brand_logo',
                title: 'Custom Brand Logo',
                help: "Appears in the campaign report's title box."
            },
            {
                type: 'image',
                name: 'share_report_header_logo',
                title: 'Custom Header Logo',
                help: "Appears in the top-left of the page."
            },
            {
                type: 'color',
                name: 'share_report_accent_color',
                title: <span>Custom Accent Color</span>,
                options: {defaultValue: '#66CC00', float: 'left'}
            },
            {
                type: 'color',
                name: 'share_report_header_color',
                title: <span>Custom Headerbar Color</span>,
                options: {defaultValue: '#111111', float: 'right'}
            },
            {
                type: 'color',
                name: 'share_report_vars.chart_color_start',
                title: <span>Charts Custom Color #1</span>,
                options: {defaultValue: '#B9ED91', float: 'left'}
            },
            {
                type: 'color',
                name: 'share_report_vars.chart_color_stop',
                title: <span>Charts Custom Color #2</span>,
                options: {defaultValue: '#206601', float: 'right'}
            },
            {
                type: 'generic',
                content: (
                    <label><strong>Enable/Disable Campaign Charts</strong></label>
                ),
                options: {useBorderTop: true, useBorderBottom: true, groupStyle: {clear: 'both'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_roi_timeseries',
                title: 'Show ROI Timeseries',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_emv_breakdown',
                title: 'Show EMV Breakdown',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },

            {
                type: 'checkbox',
                name: 'share_report_vars.show_audience_gender',
                title: 'Show Audience Gender',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_audience_age',
                title: 'Show Audience Age',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_audience_country',
                title: 'Show Audience Country',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_audience_race',
                title: 'Show Audience Race',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_audience_authenticity',
                title: 'Show Audience Authenticity',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_audience_interests',
                title: 'Show Audience Interests',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },

            {
                type: 'generic',
                content: (
                    <label><strong>Enable/Disable Additional Metrics</strong></label>
                ),
                options: {useBorderTop: true, useBorderBottom: true, groupStyle: {clear: 'both'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_ctr',
                title: 'Show Click-Through Rate',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_share_rate',
                title: 'Show Share Rate',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },
            {
                type: 'checkbox',
                name: 'share_report_vars.show_save_rate',
                title: 'Show Save Rate',
                options: {hideTitle: true, float: 'left', groupStyle: {width: '45%'}}
            },

            {
                type: 'generic',
                content: (
                    <label><strong>Override Figures</strong></label>
                ),
                options: {useBorderTop: true, useBorderBottom: true, groupStyle: {clear: 'both'}}
            },
            {
                type: 'number',
                name: 'share_report_vars.total_engagements',
                title: "Override Total Engagements",
                options: {float: 'left', groupStyle: {width: '40%'}}
            },
            {
                type: 'number',
                name: 'share_report_vars.total_impressions',
                title: "Override Total Impressions",
                options: {float: 'right', groupStyle: {width: '40%'}}
            },
            {
                type: 'number',
                name: 'share_report_vars.total_reach',
                title: "Override Total Reach",
                options: {float: 'left', groupStyle: {width: '40%'}}
            },
            {
                type: 'number',
                name: 'share_report_vars.total_followers',
                title: "Override Total Followers",
                options: {float: 'right', groupStyle: {width: '40%'}}
            },
            {
                type: 'number',
                name: 'share_report_vars.total_posts',
                title: "Override Total Posts",
                options: {float: 'left', groupStyle: {width: '40%'}}
            },
            {
                type: 'number',
                name: 'share_report_vars.total_creators',
                title: "Override Total Creators",
                options: {float: 'right', groupStyle: {width: '40%'}}
            },

        ] : [];

        return (
            <Form
                updatedFields={this.getUpdatedFields()}
                onFieldChange={this.handleFieldChange.bind(this)}
                values={settings}
                fields={[
                    {
                        type: 'checkbox',
                        name: 'share_report',
                        title: 'Publicly Share Campaign Report',
                        options: {hideTitle: true}
                    },
                    (isReportPublic && shareReportUrl) ? {
                        type: 'generic',
                        content: (<Alert classes={['success']} content={<div>Your campaign report is public! Find it at <a href={shareReportUrl} target={'_blank'} rel={'noopener noreferrer'} style={{fontWeight: 'bold', color: 'white'}}>{shareReportUrl}</a>.</div>} />)
                    } : null,
                    ...publicReportFields

                ].filter(f => !!f)}
            />
        );
    }

    renderBasicsPane() {
        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={this.getSettingsObject()}
            fields={[
                {
                    type: 'text',
                    name: 'name',
                    title: 'Campaign Name',
                    help: 'Name of this campaign. Should be clear and concise.',
                    placeholder: 'eg, Summer Style Campaign',
                },
                {
                    type: 'textarea',
                    name: 'description',
                    title: 'Short Description',
                    help: 'A 1-3 sentence description of the campaign. Will be visible to users.',
                },
                {
                    type: 'date',
                    name: 'start',
                    title: 'Start Date',
                    help: 'Indicates the kickoff date for the campaign.',
                    options: {groupStyle: {width: '45%', float: 'left'}}
                },
                {
                    type: 'date',
                    name: 'end',
                    title: 'End Date',
                    help: 'Indicates the end date for the campaign.',
                    options: {groupStyle: {width: '45%', float: 'right'}}
                },
                {
                    type: 'image',
                    name: 'image_id',
                    title: 'Campaign Image',
                    help: "Upload an image that represents the campaign.",
                    options: {groupStyle: {clear: "both"}}
                },
                {
                    type: 'file',
                    name: 'invitation_attachment',
                    title: 'Attach Brief',
                    help: "Optionally upload a PDF file for users to download before they accept the invitation. Also attached to the invitation email.",
                    options: {
                        groupStyle: {clear: "both"},
                        onChange: this.handleUploadAttachment.bind(this),
                        previewFormatter: (value, remover) => {
                            return <div style={{
                                background: '#f6f6f6',
                                padding: '10px',
                                margin: '10px 0',
                            }}>
                                <a
                                    style={{marginRight: 15}}
                                    className={'v3 icon ban'} onClick={()=>remover(null)} role={'button'} />

                                <a className={'dark'}
                                   href={value.original_url} target={'_blank'}>{value.name}</a>
                            </div>
                        }
                    }
                },
                this.props.teams.length > 0 ? {
                    type: 'select',
                    name: 'billable_team_id',
                    title: "Team",
                    help: "Assign this campaign to a team. This will change the email addresses used for campaign communication as well as charge credits to the team ledger.",
                    choices: [
                        {text: 'No Team / General Ledger', value: 0},
                        ...this.props.teams.map(team => ({text: team.name, value: team.id}))
                    ],
                    options: {groupStyle: {clear: "both"}}
                } : null,

            ]}
        />
    }

    renderTemplatesPane() {
        const settings = this.getSettingsObject();
        const makeHelpWithFormattingArticle = (text) => {
            const helpLink = <a target="_blank" href="https://support.tid.al/campaign-email-template-tags" rel="noopener noreferrer">Get formatting help.</a>
            return (
                <div>{text} {helpLink}</div>
            );
        };

        return <SectionalToolbox
            accordion={true}
            embedded={true}
            sections={[
                {
                    key: 'content_guidelines',
                    title: 'Content Guidelines',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'wysiwyg',
                                name: 'content_guidelines',
                                title: 'Content Guidelines',
                                help: makeHelpWithFormattingArticle("Describe the content you would like generated. Make sure to include anything that is required of users, any example posts, and any other instructions related to writing or posting content."),
                            },
                            {
                                type: 'wysiwyg',
                                name: 'other_post_guidelines',
                                title: "Compliance Guidelines",
                                help: makeHelpWithFormattingArticle("Use this section for compliance instructions: #sponsored tags, product or company hashtags, and any other compliance requirements.")
                            }
                        ]}
                    />
                },
                {
                    key: 'product_details',
                    title: 'Product Highlight Copy',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'wysiwyg',
                                name: 'product_details_copy',
                                title: 'Product Details',
                                help: makeHelpWithFormattingArticle("If filled out, this section will show on the campaign invitation page but not in the invitation email. Use this section to describe the campaign's product, including talking points, features, and benefits.")
                            },
                        ]}
                    />
                },
                settings.use_products === true ? {
                    key: 'product_guidelines',
                    title: 'Product Selection Guidelines',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'wysiwyg',
                                name: 'product_guidelines',
                                title: 'Product Guidelines',
                                help: makeHelpWithFormattingArticle("Describe any guidelines related to selecting product samples.")
                            },
                        ]}
                    />
                } : null,
                settings.use_products === true ? {
                    key: 'shipping_address_reminder',
                    title: 'Shipping Address Reminder',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'text',
                                name: 'shipping_address_reminder_subject',
                                title: 'Shipping Address Reminder Subject',
                                help: makeHelpWithFormattingArticle('The subject to use when sending a reminder email.')
                            },
                            {
                                type: 'wysiwyg',
                                name: 'shipping_address_reminder_copy',
                                title: 'Shipping Address Reminder Text',
                                help: makeHelpWithFormattingArticle("This text will be included in shipping address reminder emails.")
                            },
                        ]}
                    />
                } : null,
                settings.use_coupon_codes === true ? {
                    key: 'coupon_guidelines',
                    title: 'Coupon Code Guidelines',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'wysiwyg',
                                name: 'coupon_code_guidelines',
                                title: 'Coupon Code Guidelines',
                                help: makeHelpWithFormattingArticle("Describe any guidelines related to the use of coupon codes.")
                            },
                        ]}
                    />
                } : null,
                {
                    key: 'invitation',
                    title: 'Invitation Email',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'text',
                                name: 'invitation_subject',
                                title: 'Invitation Subject',
                            },
                            {
                                type: 'wysiwyg',
                                name: 'invitation_copy',
                                title: 'Invitation Text',
                                help: makeHelpWithFormattingArticle("This text will be included in invitation emails and on the campaign offer page.")
                            },
                            {
                                type: 'file',
                                name: 'invitation_attachment',
                                title: 'Invitation Email Attachment',
                                help: "Optionally attach a file to Invitation Emails. Also included in Invitation Reminders.",
                                options: {
                                    onChange: this.handleUploadAttachment.bind(this),
                                    previewFormatter: (value, remover) => {
                                        return <div style={{
                                            background: '#f6f6f6',
                                            padding: '10px',
                                            margin: '10px 0',
                                        }}>
                                            <a
                                                style={{marginRight: 15}}
                                                className={'v3 icon ban'} onClick={()=>remover(null)} role={'button'} />

                                            <a className={'dark'}
                                               href={value.original_url} target={'_blank'}>{value.name}</a>
                                        </div>
                                    }
                                }
                            },
                            settings.send_invitation_reminder_email ? {
                                type: 'text',
                                name: 'invitation_reminder_subject',
                                title: 'Invitation Reminder Subject',
                                help: makeHelpWithFormattingArticle('The subject to use when sending a reminder email.')
                            } : null,
                            settings.send_invitation_reminder_email ? {
                                type: 'wysiwyg',
                                name: 'invitation_reminder_copy',
                                title: 'Invitation Reminder Text',
                                help: makeHelpWithFormattingArticle("This text will be included in invitation reminder emails.")
                            } : null,
                        ]}
                    />
                },
                (settings.use_deadlines) ? {
                    key: 'deadlinesemails',
                    title: 'Deadline Emails',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            (settings.use_blog_drafts || settings.use_social_drafts) ? {
                                type: 'text',
                                name: 'draft_deadline_subject',
                                title: 'Draft Deadline Approaching Email Subject',
                            } : null,
                            (settings.use_blog_drafts || settings.use_social_drafts) ? {
                                type: 'wysiwyg',
                                name: 'draft_deadline_copy',
                                title: 'Draft Deadline Approaching Email Copy',
                            } : null,
                            {
                                type: 'text',
                                name: 'post_deadline_subject',
                                title: 'Post Deadline Approaching Email Subject',
                            },
                            {
                                type: 'wysiwyg',
                                name: 'post_deadline_copy',
                                title: 'Post Deadline Approaching Email Copy',
                            },
                        ].filter((field) => field !== null)}
                    />

                } : null,
                (settings.use_products && settings.send_shipping_email) ? {
                    key: 'productshippedemail',
                    title: 'Product Shipment Email',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'text',
                                name: 'product_shipped_subject',
                                title: 'Product Shipment Email Subject',
                            },
                            {
                                type: 'wysiwyg',
                                name: 'product_shipped_copy',
                                title: 'Product Shipment Email Copy',
                                help: "This text will be included in product shipment notification emails."
                            },
                        ]}
                    />
                } : null,
                (settings.send_waitlist_email || settings.send_accepted_email) ? {
                    key: 'waitlistemail',
                    title: 'Waitlist Email',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            settings.send_waitlist_email ? {
                                type: 'text',
                                name: 'waitlist_subject',
                                title: 'Waitlist Email Subject',
                                help: makeHelpWithFormattingArticle('This email will be sent when a user is waitlisted.')
                            } : null,
                            settings.send_waitlist_email ? {
                                type: 'wysiwyg',
                                name: 'waitlist_copy',
                                title: 'Waitlist Copy',
                                help: makeHelpWithFormattingArticle("This text will be included in the waitlist notification email.")
                            } : null,
                            settings.send_accepted_email ? {
                                type: 'text',
                                name: 'accepted_subject',
                                title: 'Accepted Email Subject',
                                help: makeHelpWithFormattingArticle('This email will be sent when a user is accepted after being waitlisted.')
                            } : null,
                            settings.send_accepted_email ? {
                                type: 'wysiwyg',
                                name: 'accepted_copy',
                                title: 'Accepted Email Copy',
                                help: makeHelpWithFormattingArticle("This text will be included in the acceptance notification email.")
                            } : null,
                        ]}
                    />
                } : null,
                (settings.use_payments && settings.send_invoice_email) ? {
                    key: 'invoiceemail',
                    title: 'Payment Approved Email',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'text',
                                name: 'invoice_subject',
                                title: 'Payment Approved Email Subject',
                            },
                        ]}
                    />
                } : null,
                settings.send_completed_email ? {
                    key: 'completed',
                    title: 'Completed Email',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'text',
                                name: 'completed_subject',
                                title: 'Activation Completed Subject',
                            },
                            {
                                type: 'html',
                                name: 'completed_copy',
                                title: 'Activation Completed Text',
                                help: makeHelpWithFormattingArticle("This text will be included in the activation completed notification.")
                            },
                        ]}
                    />
                }: null,
                settings.send_canceled_email ? {
                    key: 'canceled',
                    title: 'Canceled Email',
                    content: <Form
                        updatedFields={this.getUpdatedFields()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        values={settings}
                        usePadding={false}
                        fields={[
                            {
                                type: 'text',
                                name: 'canceled_subject',
                                title: 'Canceled Subject',
                            },
                            {
                                type: 'html',
                                name: 'canceled_copy',
                                title: 'Canceled Text',
                                help: makeHelpWithFormattingArticle("This text will be included in the activation canceled notification.")
                            },
                        ]}
                    />

                } : null,
                {
                    key: 'toggles',
                    title: 'Enable / Disable Automatic Emails',
                    contentStyle: {paddingLeft: 0, paddingRight: 0},
                    content: (
                        <div className="form-group">
                            <EmailSettingsTable
                                settings={settings}
                                onChange={this.handleFieldChange.bind(this)}
                            />
                        </div>
                    )
                },
            ]}
        />;


    }

    renderCouponsPane() {
        return <div style={{maxHeight: 600, overflow: 'auto'}}>
            <CampaignCouponsTable
                campaign={this.props.campaign}
            />
        </div>
    }

    renderProductsPane() {
        const settings = this.getSettingsObject();

        if (typeof settings.product_catalog_id === 'undefined' || !settings.product_catalog_id) {
            return <Alert
                classes={['info']}
                content={'First select a product catalog in the logistics tab.'}
            />;
        }

        return <div style={{maxHeight: 600, overflow: 'auto'}}>
            <ConnectedCampaignProductsTable
                showStockAvailable={true}
                embedded={true}
                campaignId={this.props.campaign.id}
                catalogId={settings.product_catalog_id}
            />
        </div>

    }

    renderPane() {
        let content;
        switch (this.state.view) {
            case 'logistics':
                content = this.renderLogisticsPane();
                break;
            case 'content':
                content = this.renderContentPane();
                break;
            case 'templates':
                content = this.renderTemplatesPane();
                break;
            case 'advanced':
                content = this.renderAdvancedPane();
                break;
            case 'coupons':
                content = this.renderCouponsPane();
                break;
            case 'products':
                content = this.renderProductsPane();
                break;
            case 'report':
                content = this.renderReportPane();
                break;
            case 'basics':
            default:
                content = this.renderBasicsPane();
        }

        return (
            <div className="campaign-settings-toolbox-pane col-xs-12 col-md-8">
                {this.renderSaveAlert()}
                {content}
            </div>
        );
    }

    renderSaveAlert() {

        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={"Saving your changes..."} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={"Changes saved!"} style={style}
            />
        }

        if (Object.keys(this.state.pendingUpdates).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>You have unsaved changes. <a className="v3 bold" onClick={this.commitUpdates.bind(this)} role="button">Save now.</a></span>}
                    style={style}
                />
            );
        }
    }

    renderContent() {
        return (
            <div className="campaign-settings-toolbox-inner container-fluid container-fullwidth">
                <div className="row">
                    {this.renderNav()}
                    {this.renderPane()}
                </div>
            </div>
        );
    }

    render() {
        return this.renderContent();
    }

}

const mapStateToProps = (state, props) => {
    return {
        lists: getLists(state, props),
        teams: getTeams(state),
        catalogs: getCatalogs(state),
        forms: getCampaignForms(state, props),
        campaigns: getCampaignsByDate(state, props),
        workflows: getWorkflows(state, props),
        channel: getCurrentChannel(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLists: () => dispatch(fetchLists()),
        fetchProductCatalogs: () => dispatch(fetchProductCatalogs()),
        fetchForms: (id) => dispatch(fetchCampaignForms(id)),
        fetchWorkflows: () => dispatch(fetchWorkflowsIfNeeded()),
        fetchReportTemplates: () => dispatch(createChannelInteraction('GetCampaignReportTemplates')),
    };
};

const ConnectedCampaignSettingsForm = connect(mapStateToProps, mapDispatchToProps)(CampaignSettingsForm);
ConnectedCampaignSettingsForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    activationFieldsOnly: PropTypes.bool,
    settings: PropTypes.object.isRequired,
};
export default ConnectedCampaignSettingsForm;
