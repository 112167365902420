/**
 * Created by bkanber on 6/9/17.
 */
import moment from 'moment';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';
import fetch from 'isomorphic-fetch';
import {socialIconForType, socialNameForType} from "./utilities/social";


export const PAID_PLANS = [
    {
        type: 'starter',
        title: 'Starter',
        description: 'One admin, 50 influencers, and 2 active campaigns.',
        options: [
            {term: 'month', title: 'Pay Monthly', price: '$750', price_cents: 75000},
            {term: 'year', title: 'Pay Annually', price: '$7,200', price_cents: 720000},
        ]
    },
    {
        type: 'standard',
        title: 'Standard',
        description: 'Two admins, 100 influencers, and 4 active campaigns.',
        options: [
            {term: 'month', title: 'Pay Monthly', price: '$1,000', price_cents: 100000},
            {term: 'year', title: 'Pay Annually', price: '$9,600', price_cents: 960000},
        ]
    },
    {
        type: 'advanced',
        title: 'Advanced',
        description: 'Five admins, 250 influencers, and 10 active campaigns.',
        options: [
            {term: 'month', title: 'Pay Monthly', price: '$2,000', price_cents: 200000},
            {term: 'year', title: 'Pay Annually', price: '$19,200', price_cents: 1920000},
        ]
    },
    {
        type: 'enterprise',
        title: 'Enterprise',
        description: 'Custom pricing for custom needs, starting at $4,000 per month.'
    }
];

export const getPlanPrice = (type, term) => {
    const plan = _find(PAID_PLANS, {type});

    if (!plan) {
        return;
    }

    const opt = _find(plan.options || [], {term});

    if (!opt) {
        return;
    }

    return opt.price;

};

export const getCollectableTypeIcon = (item) => {
    switch (item.collectable_type) {
        case 'Tidal\\Blog': return 'fa fa-rss';
        case 'Tidal\\User': return 'fa fa-user';
        case 'Tidal\\Social':
            return socialIconForType(item.profile.metadata.social_type)

    }
};

export const getNiceCollectableTypeName = (item) => {
    switch (item.collectable_type) {
        case 'Tidal\\Blog': return "Blog";
        case 'Tidal\\User': return "User";
        case 'Tidal\\Social':
            return socialNameForType(item.profile.metadata.social_type);
    }
};

export const getIconAndTypeForPost = (type) => {

    let icon, value;
    switch (type) {
        case 'youtube':
            icon = "v3 icon youtube";
            value = 'YouTube';
            break;
        case 'pinterest':
            icon = "v3 icon pinterest";
            value = 'Pinterest';
            break;
        case 'facebook':
        case 'facebook-page':
            icon = "v3 icon facebook";
            value = 'Facebook';
            break;
        case 'twitter':
            icon = "v3 icon twitter" ;
            value = 'Twitter';
            break;
        case 'instagram':
        case 'instagram-business':
            icon = "v3 icon instagram" ;
            value = 'Instagram';
            break;
        case 'instastory':
            icon = "v3 icon instagram" ;
            value = 'Instastory';
            break;
        case 'tiktok':
            icon = "v3 icon tiktok" ;
            value = 'TikTok';
            break;
        case 'post':
            icon = "v3 icon file";
            value = 'Post';
            break;
        case 'draft':
            icon = "v3 icon pencil";
            value = 'Draft';
            break;
        case 'video':
            icon = "fas fa-video";
            value = 'Video';
            break;
        default:
            icon = "v3 icon file" ;
            value = type || 'Post';
    }

    return {icon, type: value};
};

/**
 * @param state
 * @returns {string}
 */
export const getApiRoot = (state) => {

    const channel = state.presence.channel;
    const domain = channel.url;
    const proto = domain === 'dev.gator.com' ? 'http' : 'https';
    const base = domain ? proto + '://' + domain : '';

    return base;
};

/**
 * Checks if user is on a mobile device/screen size
 * @returns boolean True if page is viewed on mobile
 */

export const isMobile = () => {
    if (window.innerWidth <= 768) {
        console.log('isMobile = true');
        return true;
    } else {
        console.log('isMobile = false');
        return false;
    }
};

/**
 * Checks if an image url is valid
 * @param url
 * @param minArea
 * @returns {Promise<any>}
 */
export const isImageValid = (url, minArea = 40000) => {

    return new Promise((resolve, reject) => {

        if (!url) {
            return reject('no url');
        }

        let i = new Image();

        i.onerror = (e) => {
            return reject('error loading');
        };

        i.onload = (e) => {

            if (!e.target.complete) {
                return reject('image did not load');
            }

            const height = e.target.naturalHeight;
            const width = e.target.naturalWidth;

            if (!height || !width) {
                return reject('no height or width');
            }

            const area = height * width;

            if (area < minArea) {
                return reject('too small');
            } else {
                return resolve(url);
            }
        };

        i.src = url;
    });

};

/**
 * Returns the first valid image url in a set.
 * @param urls
 * @param minArea
 * @returns {Promise<*>}
 */
export async function findFirstValidImage (urls, minArea = 40000) {

    if (!urls || urls.length === 0) {
        return null;
    }

    for (let url of urls) {
        try {
            let good = await isImageValid(url);
            return good;
        } catch (e) {

        }
    }

    return null;

}

/**
 * Useful when making tables.
 * @param row
 * @param col
 * @returns {string}
 */
export const makeCellKey = (row, col) => {
    return `cell-key-${col.key}-${row.item.id}`;
};

/**
 *
 * @param text
 * @returns {*}
 */
export const htmlspecialchars = (text) => {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
};

/**
 * Make an authenticated fetch call.
 * It still uses credentials/session for authentication, but also automatically includes csrf token
 * @param url
 * @param params
 */
export const fetchAuthenticated = (url, params = {}) => {

    const defaultParams = {
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': getCsrfToken()
        }
    };

    const requestParams = {...defaultParams, ...params};

    return fetch(url, requestParams);

};

/**
 * @returns {*}
 */
export const getCsrfToken = () => {
    let metas = document.getElementsByTagName('meta');

    for (let i=0; i<metas.length; i++) {
        if (metas[i].getAttribute("name") === "csrf-token") {
            return metas[i].getAttribute("content");
        }
    }

    return null;
};

export const formatRangeValue = (value, formatter = (val) => val) => {
    if (!value) return null;


    if (typeof value === 'number') {
        return formatter(value);
    }

    let output = [];

    if (typeof value.min !== 'undefined') {
        output.push(formatter(value.min));
    }
    if (typeof value.max !== 'undefined') {
        output.push(formatter(value.max));
    }

    return output.join('-')

};

export const setLocalStorage = (key, value) => {
    if (hasLocalStorage) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getLocalStorage = (key, defaultValue = null) => {
    if (!hasLocalStorage) return defaultValue;
    const val = window.localStorage.getItem(key);
    if (val === null) return defaultValue;
    return JSON.parse(val);
};

export const makeStringSortFn = (dir = 'asc') => (a, b) => {
    const comparison = a.localeCompare(b, {})
    const multiplier = (dir === 'asc') ? 1 : -1;
    return comparison * multiplier;
};

export const makeNumberSortFn = (dir = 'asc') => (a, b) => {
    const comparison = a > b ? 1 : -1;
    const multiplier = (dir === 'asc') ? 1 : -1;
    return comparison * multiplier;
};

export const makeDateSortFn = (dir = 'asc') => (a, b) => {
    // Comparison is asc by default -- newer dates come last
    const comparison = a.isBefore(b) ? -1 : 1;
    const multiplier = (dir === 'asc') ? 1 : -1;
    return comparison * multiplier;
};

export const convertDatesToMoment = (obj, dateKeys) => {
    let newObj = {...obj};
    for (let dateKey of dateKeys) {
        let originalValue = _get(newObj, dateKey);
        if (!originalValue) continue;
        let newDate = makeMomentFromDate(originalValue);
        _set(newObj, dateKey, newDate);
    }
    return newObj;
};

export const makeMomentFromDate = date => {

    if (!date) {
        return null;
    }

    if (typeof date === 'string') {
        return moment(date);
    }

    if (typeof date === 'object' && date.date) {

        let m = moment(date.date);

        if (date.timezone) {
            // This seems to break things?
            // m = m.parseZone(date.timezone);
        }

        return m;
    }

    return null;
};

export const shortTimeDiff = ts => ts.fromNow().replace('minute', 'min').replace('second', 'sec');

export const hasPushState = ('history' in window && 'pushState' in window.history);

export const hasLocalStorage = (() => {
    var test = 'test';
    try {
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
})();

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function preventDefaultIfPossible(event) {
    if (typeof event !== 'undefined' && typeof event.preventDefault !== 'undefined') {
        event.preventDefault();
    }
}

export function stopPropagationIfPossible(event) {
    if (typeof event !== 'undefined' && typeof event.stopPropagation !== 'undefined') {
        event.stopPropagation();
    }
}

export function indexArrayById(arr) {
    return arr.reduce(function(map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});
}

export const c3ColorPattern = {
    pattern: [
        '#206601', // darker green
        '#257900', // darker green
        '#469A01', // darker green
        '#58B305', // lighter green
        '#66CC00', // lighter green
        '#7ED82F', // lighter green
        '#82DB3D',
        '#95E15B', // lighter green
        '#A6E874', // lighter green
        '#B9ED91',
    ],
    distinct: [
        // source: https://sashamaps.net/docs/resources/20-colors/
        '#e6194B',
        '#3cb44b',
        '#ffe119',
        '#4363d8',
        '#f58231',
        '#911eb4',
        '#42d4f4',
        '#f032e6',
        '#bfef45',
        '#fabed4',
        '#469990',
        '#dcbeff',
        '#9A6324',
        '#fffac8',
        '#800000',
        '#aaffc3',
        '#808000',
        '#ffd8b1',
        '#000075',
        '#a9a9a9'
    ],
    rivernights: [
        // source: https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
        "#b30000",
        "#7c1158",
        "#4421af",
        "#1a53ff",
        "#0d88e6",
        "#00b7c7",
        "#5ad45a",
        "#8be04e",
        "#ebdc78"
    ]

};

export function getImageSizeRecord(image, key) {

    const sizes = (image || {}).sizes || [];
    if (sizes) {
        for (let size of sizes) {
            if (size.key === key) {
                return size;
            }
        }
    }

    return null;
}

export function filterSmallImages(images, minSize = 100000) {
    if ((images || []).length === 0) {
        return [];
    }

    return (images || []).filter(image => {
        if (typeof image === 'string') {
            return true;
        }
        const width = image.width || 0;
        const height = image.height || 0;
        const area = parseInt(width, 10) * parseInt(height, 10);

        return area > minSize;
    });

}

export function filterLogoImages(images) {

    return (images || [])
        .filter(image => {
            return (image.tags || []).indexOf('Label: Logo') === -1
        });
}

export function getBestImageFromList(images) {

    if ((images || []).length === 0) {
        return null;
    }

    const filteredBySize = filterSmallImages(images);

    const filteredByLogos = filterLogoImages(filteredBySize.length > 0 ? filteredBySize : images);

    if (filteredByLogos.length > 0) {
        return filteredByLogos[0];
    }

    return images[0];

}

export function proxyImageUrl(urlToProxy) {
    let shouldProxy = true;
    const dontProxyFor = [
        // 'cdn.tid.al',
        // 'cloudfront.net',
        // 'rackcdn'
    ];

    for (const domain of dontProxyFor) {
        if (urlToProxy.indexOf(domain) > -1) {
            shouldProxy = false;
        }
    }


    if (shouldProxy) {
        // Remove slashes cause they mess with routing. Make sure to reinstall on the backend.
        const encodedUrl = btoa(urlToProxy).replace(/\//g, '_');
        return '/proxy/' + encodedUrl + '/img';
    } else {
        return urlToProxy;
    }
};

export function getImageUrl(image) {

    if (!image) {
        return null;
    }

    if (typeof image === 'string') {
        return image;
    }

    let medium = getImageSizeRecord(image, 'm');
    if (medium && medium.cdn) {
        return medium.cdn;
    } else if (medium && medium.url) {
        return medium.url;
    }

    let original = getImageSizeRecord(image, 'o');
    if (original && original.cdn) {
        return original.cdn;
    } else if (original && original.url) {
        return original.url;
    }

    if (image.cdn) {
        return image.cdn;
    }

    if (image.url) {
        return image.url;
    }

    if (image.original_url) {
        return image.original_url;
    }

    return null;

};

/**
 * @param object
 * @returns {FormData}
 */
export function makeFormData(object) {
    const formData = new FormData();

    Object.keys(object).forEach(key => {

        let value = object[key];

        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }

        formData.append(key, value);
    });

    return formData;
}

/**
 * @param {String} text
 * @param {Number} limit
 * @param {String} endChar
 * @returns {*}
 */
export function limitChars(text, limit = 100, endChar = '...') {

    if (text === null || typeof text === 'undefined') return null;

    if (text.length > limit) {
        text = text.slice(0, limit) + endChar;
    }

    return text;

}

export function isScientificNotation(x) {
    return x.toString().toLowerCase().indexOf('e') > -1;
}

export function convertFromScientificNotation(x) {
    if (isScientificNotation(x)) {
        return parseFloat(x);
    }
    return x;
}
/**
 * @param {Number} x
 * @param {Number} decimals
 * @returns {string}
 */
export function formatNumber(x, decimals=0) {
    x = parseFloat(x);
    return x.toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberK(x, decimals=0) {
    x = parseFloat(x);

    if (x === 0) {
        return 0;
    }

    if (x >= 1000000) {
        return formatNumber(x/1000000, decimals)+"M";
    }

    if (x >= 1000) {
        return formatNumber(x/1000, decimals)+'k';
    }

    return formatNumber(x, decimals);
}

export function formatDollar(x, decimals=0) {
    return '$' + formatNumber(x, decimals);
}

export function formatCent(x) {
    x = formatNumber(x*100);
    return x + String.fromCharCode(162);
}

export function formatPound(x, decimals=0) {
    return '£' + formatNumber(x, decimals);
}

export const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

export function standardDeviation(values){
    var avg = average(values);

    var squareDiffs = values.map(function(value){
        var diff = value - avg;
        var sqrDiff = diff * diff;
        return sqrDiff;
    });

    var avgSquareDiff = average(squareDiffs);

    var stdDev = Math.sqrt(avgSquareDiff);
    return stdDev;
};

export function ucwords(str) {
    //  discuss at: http://locutus.io/php/ucwords/
    // original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // improved by: Waldo Malqui Silva (http://waldo.malqui.info)
    // improved by: Robin
    // improved by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Cetvertacov Alexandr (https://github.com/cetver)
    //    input by: James (http://www.james-bell.co.uk/)
    //   example 1: ucwords('kevin van  zonneveld')
    //   returns 1: 'Kevin Van  Zonneveld'
    //   example 2: ucwords('HELLO WORLD')
    //   returns 2: 'HELLO WORLD'
    //   example 3: ucwords('у мэри был маленький ягненок и она его очень любила')
    //   returns 3: 'У Мэри Был Маленький Ягненок И Она Его Очень Любила'
    //   example 4: ucwords('τάχιστη αλώπηξ βαφής ψημένη γη, δρασκελίζει υπέρ νωθρού κυνός')
    //   returns 4: 'Τάχιστη Αλώπηξ Βαφής Ψημένη Γη, Δρασκελίζει Υπέρ Νωθρού Κυνός'
    return (str + '')
        .replace(/^(.)|\s+(.)/g, function ($1) {
            return $1.toUpperCase()
        });
}

export const base64_png_1pxSolidEEE = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gYJEiIA3QCbPQAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAADElEQVQI12N49+4dAAWYAsv4WALrAAAAAElFTkSuQmCC';

export const countries = {
    "US": "United States",
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegowina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, the Democratic Republic of the",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote d'Ivoire",
    "HR": "Croatia (Hrvatska)",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "TP": "East Timor",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "FX": "France, Metropolitan",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard and Mc Donald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea, Democratic People's Republic of",
    "KR": "Korea, Republic of",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macau",
    "MK": "Macedonia, The Former Yugoslav Republic of",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States of",
    "MD": "Moldova, Republic of",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint LUCIA",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia (Slovak Republic)",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SH": "St. Helena",
    "PM": "St. Pierre and Miquelon",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen Islands",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna Islands",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "YU": "Yugoslavia",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
};

export const validateUrl = (input) => {
    try {
        const url = new URL(input);
        // return url.protocol === 'http:' || url.protocol === 'https:';
        return true;
    } catch(error) {
        return false;
    }
};

export const getDomain = (input) => {
    try {
        const host = new URL(input).host;
        return host.substr(0,4) === 'www.' ? host.slice(4) : host;
    } catch (error) {
        return input;
    }
};

export const slugify = (value) => value.replace(/\s+/g, '-').toLowerCase();

export const stripSlashes = function (str) {
    return (str + '')
        .replace(/\\(.?)/g, function (s, n1) {
            switch (n1) {
                case '\\':
                    return '\\'
                case '0':
                    return '\u0000'
                case '':
                    return ''
                default:
                    return n1
            }
        })
}

export const makeQuery = (params) => {
    return Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&');
};

export const isReachVerified = (blogs = []) => {
    let count = 0;

    blogs.forEach(blog => {
        if (blog.is_verified || blog.traffic === 0) {
            count +=1;
        }
    });

    return count === blogs.length;
};

export const CHANNEL_STATUSES = {
    0: 'Member',
    1: 'Invited',
    2: 'Blocked',
    4: 'Imported',
    8: 'Opted Out',
    16: 'Spam',
    32: 'Active',
    1024: 'Accepted',
    64: 'Rejected',
    128: 'Unresponsive',
    256: 'Suspended',
    512: 'Preferred',
};

export function toDotNotation(obj, opt_out, opt_paths) {
    var out = opt_out || {};
    var paths = opt_paths || [];
    return Object.getOwnPropertyNames(obj).reduce(function(out, key) {
        if (key === 'length') {
            return out;
        }
        paths.push(key);
        if (typeof obj[key] === 'object') {
            toDotNotation(obj[key], out, paths);
        } else {
            out[paths.join('.')] = obj[key];
        }
        paths.pop();
        return out;
    }, out)
}

export const scrollToBottomThenTop = () => new Promise(async (resolve, reject) => {
    const moveDownAndWait = () => {
        const scrollTo = window.scrollY + window.innerHeight;
        return new Promise((resolve, reject) => {
            window.scrollTo(0, window.scrollY + window.innerHeight);
            setTimeout(() => {
                resolve();
            }, 250);
        });
    };

    do {
        await moveDownAndWait();
    } while (window.scrollY + window.innerHeight < document.body.clientHeight);


    setTimeout(() => {
        window.scrollTo(0, 0);
        resolve();
    }, 1000);
});

export const getImageDimensions = (data) => {
    return new Promise((resolve, reject) => {
        const i = new Image();
        i.onload = function() {
            resolve([i.width, i.height]);
        };
        i.src = data;
    });
}

