import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

export default class FormRange extends Component {

    static defaultProps = {
        min: 0,
        max: 100,
        step: 1,
        formatLabel: (value) => '',
    };

    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.object,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        formatLabel: PropTypes.func,
    };

    render() {
        return (
            <InputRange
                step={this.props.step}
                maxValue={this.props.max}
                minValue={this.props.min}
                value={this.props.value || {min: this.props.min, max: this.props.max}}
                onChange={this.props.onChange}
                formatLabel={this.props.formatLabel}
            />
        );
    }
}
