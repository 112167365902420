/**
 * Created by bkroger on 7/20/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import SelectProductTable from '../../../Activation/SelectProductTable';
import SelectableProductGrid from '../Pieces/SelectableProductGrid';
import Alert from '../../../Common/Alert';
import Twig from 'twig';

class SelectProducts extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object.isRequired,
        twigContext: PropTypes.object.isRequired,
        createInteraction: PropTypes.func.isRequired,
    };

    renderProductGuidelines() {
        const {activation} = this.props;

        const numSamples = activation.settings.number_samples;
        let guidelines = `<span>Choose ${numSamples} product sample${numSamples !== 1 ? 's' : ''} by clicking the hearts below.</span>`;

        if ((activation.settings || {}).product_guidelines) {
            const template = Twig.twig({
                data: (activation.settings || {}).product_guidelines || '',
            });
            const rendered = template.render(this.props.twigContext);
            guidelines = rendered;
        }

        return {__html: DOMPurify.sanitize(guidelines)};
    }

    renderAlert() {
        if (this.props.activation.status.is_product_shipped) {
            return <Alert
                classes={['danger']}
                style={{marginTop: 20}}
                content={'Your product samples have already shipped so you can no longer make any changes here.'}
            />;
        }
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }

    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        return (
            <div className="influencer-page-template select-products">
                <div className="widget">
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">Product Preferences</h4>
                        <div className="v3 light h7 pane-info product-guidelines" dangerouslySetInnerHTML={this.renderProductGuidelines()}></div>
                        {this.renderAlert()}
                        <SelectableProductGrid
                            activation={this.props.activation}
                            campaignId={this.props.campaign.id}
                            onSaved={() => {}}
                            showStockAvailable={false}
                            createInteraction={this.props.createInteraction}
                        />
                    </div>
                </div>
            </div>
        )
    }


}

export default SelectProducts;

